<template>
  <div class="" v-if="item">
    <div class="w-56 overflow-hidden">
      <a
        href="https://api.whatsapp.com/send?phone=+573104472762&text=Hola%20bienvenido%20al%20canal%20de%20atención%20de%20Agrogo.%20¿En%20que%20podemos%20ayudarte?"
        target="_blank"
        class="cursor-pointer font-dinpro font-normal text-agrogo-yellow-1 text-xl text-left mb-3 md:font-normal flex"
      >
        <span class="icon-whatsapp text-xl mr-4 md:mr-2"></span>
        <p class="break-all">Chatea con nosotros</p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      telephone: null,
    };
  },
};
</script>

<style></style>
